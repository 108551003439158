import React from "react";
import "./AboutMeStyle.css";
import myProfile from "../images/profile-image2.png";

export default function AboutMe({ id }) {
  return (
      <div className="about-container" id={id}>
        <div className="aboutMe-text">
          <h3>Hey there, 👋</h3>
          I'm <span>Bayu Satrio Wibowo</span>, a student pursuing <span>Informatics</span> at Telkom University, Indonesia. I build websites with a focus on responsiveness, accessibility, and pleasing aesthetics. Being well-versed in both frontend and backend technologies, I can efficiently design, code, and deploy full-stack web applications. I'm always open to <span>new opportunities</span> to create impactful software solutions. Let's collaborate and craft some digital masterpieces together.
          <br/> <br/>
          Beyond coding, <span>I'm a gamer at heart 🎮</span> – I enjoy spending my free time exploring different games and getting lost in virtual worlds. Gaming is my way of unwinding and sparking creativity. Whether it's strategizing in multiplayer games or immersing myself in captivating storylines, I find it a great way to relax and recharge.
          Want to know more about me? Feel free to <a className='resume' href="https://read.cv/baysatriow" target="_blank" rel="noopener noreferrer">explore my resume.</a>
        </div>
        <div className="profile-photo">
          <div className="heading"> About Me</div>
          <img className="img" src={myProfile} alt="Profile"></img>
        </div>
        <div className="name">Bayu</div>
      </div>
  );
}
