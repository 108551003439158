import React from 'react'
import "./SkillCardStyle.css";
import {DiGit} from "react-icons/di";
import {SiMysql, SiPhp, SiVisualstudiocode, SiReact, SiBootstrap, SiFigma } from "react-icons/si";
import {TbBrandLaravel ,TbBrandGolang, TbBrandCss3, TbBrandJavascript, TbBrandHtml5} from "react-icons/tb";
import {IoLogoWindows, IoLogoNpm} from "react-icons/io"
import { FaUbuntu } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";

export default function SkillCard({id}) {
  return (
    <>
        <div className="skill-container" id = {id}>
          <div className="skill-header">My Skills</div>
          <div className="skill-box">
            <h2>Languages</h2>
            <div className="skillset">
              <abbr title='HTML 5'><TbBrandHtml5 className='techLogo'/></abbr>
              <abbr title='JavaScript'><TbBrandJavascript className='techLogo'/></abbr>
              <abbr title='PHP'><SiPhp className='techLogo'/></abbr>
              <abbr title='Golang'><TbBrandGolang className='techLogo'/></abbr>
            </div>                     
          </div>
          <div className="skill-box">
            <h2>Libraries and Frameworks</h2>
            <div className="skillset">
              <abbr title='Laravel'><TbBrandLaravel className='techLogo'/></abbr> 
              <abbr title='Mysql'><SiMysql className='techLogo'/></abbr>
              <abbr title='Postgresql'><BiLogoPostgresql className='techLogo'/></abbr>
              <abbr title='ReactJS'><SiReact className='techLogo'/></abbr>
              <abbr title='CSS 3'><TbBrandCss3 className='techLogo'/></abbr>
              <abbr title='Git/Github'><DiGit className='techLogo'/></abbr>
              <abbr title='NPM'><IoLogoNpm className='techLogo'/></abbr>
              <abbr title='Bootstrap'><SiBootstrap className='techLogo'/></abbr>
            </div>            
          </div>
          <div className="skill-box">
            <h2>Tools & Systems</h2>
            <div className="skillset">
              <abbr title='Visual Studio Code'><SiVisualstudiocode className='techLogo'/></abbr>
              <abbr title='Windows'><IoLogoWindows className='techLogo'/></abbr>
              <abbr title='Ubuntu'><FaUbuntu className='techLogo'/></abbr>
              <abbr title='Figma'><SiFigma className='techLogo'/></abbr>
            </div>            
          </div>
        </div>
    </>

  )
}
