import React from "react";
import "./index.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import HomeBanner from "./components/HomeBanner";
import ProjectCard from './components/ProjectCard'
import AboutMe from './components/AboutMe'
import SkillCard from './components/SkillCard'
import ContactForm from './components/ContactForm'
import AnimatedCursor from "react-animated-cursor"


function App() {
  return (
    <>
    <AnimatedCursor
      color="#fff"
      innerSize={8}
      outerSize={50}
      innerScale={1}
      outerScale={2.2}
      outerAlpha={0}
      outerStyle={{
        background: '#ffffff',
        mixBlendMode: 'exclusion'
      }}
      innerStyle={{
        backgroundColor: '#F94892'
      }}
    />
    <div>
      <Navbar />
      <HomeBanner id = "home"/>
      <ProjectCard
        id = "project"
        className = "odd"
        projectTitle="Website Top Up Game / PPOB"
        projectDesc="Develop a web platform for game top-up services and Payment Point Online Bank (PPOB) transactions, Created With Laravel"
        projectLink="#"
        deployedProjectLink="#"
        projectImg={require('./images/project-omnifyzl.png')}
      />

      <ProjectCard
        projectTitle="Website PPDB"
        projectDesc="Develop a web platform for managing the admissions process for new students (PPDB) in educational institutions, Created With PHP & Boostrapt"
        projectLink="#"
        deployedProjectLink="#"
        projectImg={require('./images/project-abdimasl.png')}
      />

      <ProjectCard
        className = "odd"
        projectTitle="Website E-Commerce"
        projectDesc="Develop a robust e-commerce platform for online retail, using Go (Golang) and PHP (native) with PostgreSQL for database management."
        projectLink="#"
        deployedProjectLink="#"
        projectImg={require('./images/project-alprol.png')}
      />

       <ProjectCard
        projectTitle="Website CRM"
        projectDesc="Develop a Customer Relationship Management (CRM) website to manage interactions with customers, streamline processes, and improve overall efficiency."
        projectLink="#"
        deployedProjectLink="#"
        projectImg={require('./images/project-crml.png')}
      />

      <AboutMe id = 'about' />
      <SkillCard id = 'skills' />
      <ContactForm id = 'contact' />
      <Footer />
    </div>
    </>
  );
}

export default App;
