import React from 'react';
import "./FooterStyle.css";

export default function Footer() {
  const styleSymbol = {
    fontSize: 20,
    marginRight: 7,
    marginLeft: 7
  }

  const currentYear = new Date().getFullYear();

  return (
    <div className='footer'>
        Made with ❤ by Bayu <span style={styleSymbol}>©</span> {currentYear}
    </div>
  )
}
